<template>
  <li class="patient-preparation-item">
    <section v-if="!isEdit" class="item-info d-inline-block text-truncate">
      <DragDrop class="icon drag" />
      <span class="item-name ">{{ item.name }}</span>
    </section>

    <section v-if="!isEdit" class="d-flex ml-auto">
      <Edit class="ml-auto icon edit" @click="toogleEdit" />
      <Trash class="ml-2 icon trash" @click="confirmDelete" />
    </section>

    <section class="d-flex align-items-center w-100 mb-3" v-else>
      <b-input
        placeholder="Descrever etapa"
        autocomplete="off"
        v-model="name"
        debounce="500"
        :autofocus="isEdit"
      />
      <Close class="icon ml-2" @click="closeEdit" />
    </section>
  </li>
</template>

<script>
export default {
  name: 'PatientPreparationItem',
  display: 'Transitions',
  data: () => ({
    edit: false,
    name: '',
  }),
  props: {
    item: {
      required: true,
      type: Object
    },
    deletePatientPreparationTemplate: {
      required: true,
      type: Function
    }
  },
  computed: {
    isEdit() {
      return this.edit || this.item.edit
    }
  },
  components: {
    DragDrop: () => import('@/assets/icons/drag-drop.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Close: () => import('@/assets/icons/close.svg')
  },
  methods: {
    toogleEdit() {
      this.edit = !this.item.edit
      this.name = this.item.name
      this.$emit('fixedItem', this.item)
    },
    closeEdit() {
      this.toogleEdit()
      this.$emit('closeEdit', this.item)
    },
    confirmDelete() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente apagar a etapa <span>${this.item?.name}</span>? Não será possível desfazer essa alteração.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deletePatientPreparationTemplate(this.item?.id)
        }
      })
    }
  },
  watch: {
    name: {
      handler(value) {
        if (this.isEdit) {
          this.$emit('updateEnabled', value?.length)
          this.$emit('updateName', value)
        }
      },
      immediate: true
    },
    'item.edit': {
      handler(value) {
        this.edit = value
        if (this.isEdit) this.$emit('updateEnabled', value?.length)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-preparation-item {
  display: flex;
  align-items: center;
  list-style: none;
  .item-info {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--type-active);
  }
  width: 100%;
  height: 6vh;
  padding: 0 10px;
  font-weight: 500;
  border-bottom: 1px solid var(--neutral-200);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .item-name {
    font-size: min(1.2vw, 16px);
    color: var(--type-active);
    font-weight: 400;
  }

  .icon {
    width: 20px;
    height: 20px;

    &.edit {
      stroke: var(--blue-500);
    }

    &.trash {
      stroke: var(--states-red-soft);
    }

    &.drag {
      width: 25px !important;
    }
  }
}
</style>for
